
/* global style */
.hidden { display: none; }

.ant-collapse{ 


  width: 100% !important;
}



.check-box-disabled{ 


  background-color: rgb(236, 63, 63);
}

.check-box-active {
  
   background-color:rgb(99, 228, 99);
}


.ant-pro-form-group-container{ 

  gap:20px 20px !important
}
