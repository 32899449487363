// FONT FACE --NUNITO
@font-face {
  font-family: Nunito;
  font-weight: 300;
  src: url("../fonts/Nunito/static/Nunito-Light.ttf");
}
@font-face {
  font-family: Nunito;
  font-weight: 400;
  src: url("../fonts/Nunito/static/Nunito-Regular.ttf");
}
@font-face {
  font-family: Nunito;
  font-weight: 500;
  src: url("../fonts/Nunito/static/Nunito-Medium.ttf");
}
@font-face {
  font-family: Nunito;
  font-weight: 600;
  src: url("../fonts/Nunito/static/Nunito-SemiBold.ttf");
}
@font-face {
  font-family: Nunito;
  font-weight: 700;
  src: url("../fonts/Nunito/static/Nunito-Bold.ttf");
}

//
html body {
  @apply font-body antialiased;
}

h1,
h2,
h3,
h4 {
  @apply font-display;
}

:root {
  --font-display: Nunito;
  --font-body: Nunito;
}