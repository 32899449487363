// Change your color you want --- please input rgb color (ex: 22,33,44)

// THEME PALETTE 10 indigo-coolGrey ==========================================
:root {
  --font-size: 15px;
  --header-height: 80px;
  /* --logo-size: 35px; */
  --logo-size: calc(var(--font-size) * 2.4);
  /* --checkbox-size: 17px; */
  --checkbox-size: calc(var(--font-size) * 1.0625);
  /* --checkbox-color: #deddf3; */
  --checkbox-color: #d6d6dc;
  /* --sidebar-width: 190px; */
  --sidebar-width: calc(var(--font-size) * 11.875);
  /* --right-section-width: 240px; */
  --right-section-width: calc(var(--font-size) * 15);

  /** -- COLORS -- **/
  --black: #121212;
  --white: #fff;
  --off-white: #f7f7f7;
  --light-green: #d5f4a9;
  /* --green: #95c11f; */
  --green: #8dc63f;
  --green-hover: #a2cf63;
  --green-active: #537424;
  --green-disabled: #95c84e;
  --light-blue: #f1f1f6;
  --blue: #262262;
  --blue-hover: #4f4c7f;
  --blue-active: #131130;
  --blue-disabled: #6e6aa3;
  --light-red: #ffdada;
  --red: #ff4444;
  /* --red-hover: #ea1e1e; */
  --red-hover: #ff6666;
  --red-active: #eb2a2a;
  --red-disabled: #e65b5b;
  --dark-gray: #a8a8a8;
  --gray: #cbcbcb;
  --light-gray: #ebebeb;
  --scrollbar-track-color: #f1f1f1;
  --scrollbar-thumb-color: #d3d3d3;
  --placeholder-color: #b4b7b9;
  --body-text-color: var(--black);
  --body-bg-color: #eeedf2;
  --heading-color: var(--blue);
  --form-label-color: var(--black);
  --form-control-bg-color: #fafafc;
  /* --form-control-focus-shadow-color: var(--green); */
  --form-control-focus-shadow-color: #8ce018;
  --group-color: #310f38;
  --project-color: #2b3990;
  --contest-color: #ff991b;
  --internship-color: #92278f;
  --venture-color: #664ea6;
}

:root {
  // PRIMARY COLOR
  --c-primary-50: #a2b08f;
  --c-primary-100: #a2b08f;
  --c-primary-200: #d5f4a9;
  --c-primary-300: #94b565;
  --c-primary-400: #a2cf63;
  --c-primary-500: #90bf4e;
  --c-primary-600: #8dc63f;
  --c-primary-700: #87c730;
  --c-primary-800: #7ec71c;
  --c-primary-900: #74c704;

  // SECONDARY COLOR
  --c-secondary-50: #b8b6e0;
  --c-secondary-100: #7874b3;
  --c-secondary-200: #4f4c7f;
  --c-secondary-300: #4a4596;
  --c-secondary-400: #3d3987;
  --c-secondary-500: #3d3987;
  --c-secondary-600: #262262;
  --c-secondary-700: #262262;
  --c-secondary-800: #222045;
  --c-secondary-900: #131130;

  // NEUTRAL COLOR
  --c-neutral-50: 249, 250, 251;
  --c-neutral-100: 243, 244, 246;
  --c-neutral-200: 229, 231, 235;
  --c-neutral-300: 209, 213, 219;
  --c-neutral-400: 156, 163, 175;
  --c-neutral-500: 107, 114, 128;
  --c-neutral-600: 75, 85, 99;
  --c-neutral-700: 55, 65, 81;
  --c-neutral-800: 31, 41, 55;
  --c-neutral-900: 17, 24, 39;
}